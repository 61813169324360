html {
  box-sizing: border-box;
}

html *,
html *:before,
html *:after {
  box-sizing: inherit;
}

html[dir="rtl"] #launcher-frame {
  right: unset !important;
  left: 16px;
}
